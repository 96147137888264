<template>
  <div id="countersWrapper" class="counters-wrapper" :data-test-id="elementTest">
    <CarouselContainer element-parent="countersWrapper" :test-id="elementTest">
      <template #contentCarouselContainer>
        <div class="counters" :data-test-id="elementTest + '-content'">
          <div class="counters-blocks">
            <ImageLazy
              class="counters-block"
              src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/bg-counters-mission.jpg"
              :background-image="true"
              :data-test-id="elementTest + '-carbone'"
              width="679"
              max-height="509"
            >
              <div
                class="counters-block-mission"
                :data-test-id="elementTest + '-carbone-mission'"
                @click="openNewProductSidebar()"
              >
                <div class="counters-block-overlay" />
                <h3 class="counters-block-title" :data-test-id="elementTest + '-carbone-title'">
                  {{ $t('home.missionTitle') }}
                </h3>
                <div class="counters-block-info" :data-test-id="elementTest + '-carbone-info'">
                  <div v-if="co2">
                    <div class="counters-block-info-count" :data-test-id="elementTest + '-carbone-info-count'">
                      {{ co2.toLocaleString() }}
                    </div>
                    <p class="counters-block-info-subtitle" :data-test-id="elementTest + '-carbone-info-subtitle'">
                      {{ $t('home.missionSubtitle') }}
                    </p>
                  </div>
                  <div class="icon-carbone u-bg-white" :data-test-id="elementTest + '-icon-carbone'" />
                </div>
                <div class="counters-block-button" :data-test-id="elementTest + '-carbone-button'">
                  <MainButton tag="div" :label="$t('home.missionButton')" class-variant="btn-white-blue" />
                </div>
              </div>
            </ImageLazy>
            <ImageLazy
              class="counters-block"
              src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/bg-counters-stock.jpg"
              :background-image="true"
              :data-test-id="elementTest + '-stock'"
              width="679"
              max-height="509"
            >
              <div
                class="counters-block-stock"
                :data-test-id="elementTest + '-stock-block'"
                @click="$router.push(localePath('category-new-products'))"
              >
                <div class="counters-block-overlay" />
                <h3 class="counters-block-title" :data-test-id="elementTest + '-stock-title'">
                  {{ $t('home.stockTitle') }}
                </h3>
                <div class="counters-block-info" :data-test-id="elementTest + '-stock-info'">
                  <div v-if="products">
                    <div class="counters-block-info-count" :data-test-id="elementTest + '-stock-info-count'">
                      {{ products.toLocaleString() }}
                    </div>
                    <p class="counters-block-info-subtitle" :data-test-id="elementTest + '-stock-info-subtitle'">
                      {{ $t('home.stockSubtitle') }}
                    </p>
                  </div>
                  <div class="icon-cart u-bg-white" :data-test-id="elementTest + '-icon-stock'" />
                </div>
                <div class="counters-block-button" :data-test-id="elementTest + '-stock-button'">
                  <MainButton
                    tag="a"
                    :label="$t('home.stockButton')"
                    :link="localePath('category-new-products')"
                    class-variant="btn-white-blue"
                  />
                </div>
              </div>
            </ImageLazy>
          </div>
        </div>
      </template>
    </CarouselContainer>
    <RightSidebarSeoModal :active="newProduct.isSidebarOpen">
      <template #content>
        <div class="right-sidebar-seo-modal-container-header" :data-test-id="elementTest + '-sidebar-header'">
          <div class="right-sidebar-seo-modal-container-header-title" :data-test-id="elementTest + '-sidebar-title'">
            <span>{{ $t('product.newProductLink') }}</span>
          </div>
        </div>
        <div class="right-sidebar-seo-modal-container-content u-p-md" :data-test-id="elementTest + '-sidebar-content'">
          <div v-html="newProduct.content" />
          <div class="u-d-f u-jc-fe">
            <NuxtLinkLocale
              :to="{ name: 'mission' }"
              class="u-c-dark-blue u-d-f u-ai-fe u-f-gap-sm"
              :data-test-id="elementTest + '-sidebar-link'"
            >
              {{ $t('product.newProductLink') }}
              <div class="icon-arrow-right u-bg-dark-blue" />
            </NuxtLinkLocale>
          </div>
        </div>
      </template>
    </RightSidebarSeoModal>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useDynamicContentStore } from '@/stores/dynamicContent'

import CarouselContainer from '@/components/Carousel/CarouselContainer'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import RightSidebarSeoModal from '@/components/Modals/SidebarModal/RightSidebarSeoModal'

export default {
  name: 'CountersIndex',
  components: {
    CarouselContainer,
    MainButton,
    RightSidebarSeoModal,
  },
  props: {
    elementTest: { type: String, default: '' },
  },
  data() {
    return {
      newProduct: {
        isSidebarOpen: false,
        content: this.$t('product.newProductBlock'),
      },
    }
  },
  computed: {
    ...mapState(useDynamicContentStore, {
      co2: store => store.counters.co2,
      products: store => store.counters.products,
    }),
  },
  beforeMount() {
    this.$bus.on('closeRightSidebar', () => {
      // Auto close when no selection
      this.closeRightSidebarModal()
    })
    this.$bus.on('openNewProductSidebar', () => {
      this.openNewProductSidebar()
    })
  },
  beforeUnmount() {
    this.$bus.off('closeRightSidebar')
    this.$bus.off('openNewProductSidebar')
  },
  methods: {
    closeRightSidebarModal() {
      this.newProduct.isSidebarOpen = false
    },

    openNewProductSidebar() {
      this.newProduct.isSidebarOpen = true
      this.newProduct.content = this.$t('product.newProductBlock', {
        co2Saved: this.co2.toLocaleString(),
        productQuantity: this.products.toLocaleString(),
      })
    },
  },
}
</script>

<style lang="scss">
@include mq-btw($mq-sm, $mq-xl) {
  .counters-wrapper .carousel-icon {
    display: none !important;
  }
}

.counters {
  cursor: pointer;
  width: 100%;

  @include mq($mq-sm) {
    width: auto;
  }

  &-blocks {
    display: flex;
    gap: $spacing-md;
  }

  &-block {
    position: relative;
    padding: $spacing-xl;
    border-radius: $spacing-xs;
    overflow: hidden;
    width: 50% !important;
    text-decoration: none;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(0);
      transition: 0.4s ease-in-out;
    }

    &:hover {
      .btn {
        background-color: var(--dark-blue);
        color: var(--white);
      }
      &:before {
        filter: blur(25px);
        transition: 0.4s ease-in-out;
      }
    }

    &-overlay {
      position: absolute;
      background: rgba(5, 23, 47, 0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &-title {
      position: relative;
      font-size: pxToRem(20px);
      font-weight: 800;
      padding-bottom: $spacing-md;
      color: var(--white) !important;
    }

    &-info {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $spacing-md;
      flex: 1;

      & * {
        color: var(--white) !important;
      }

      &-count {
        font-size: pxToRem(30px);
        font-weight: 800;
      }

      &-subtitle {
        font-size: pxToRem(15px) !important;
        font-weight: 400;
      }
    }
  }

  // &-block-mission:before {
  //   background-image: url('/counters/bg-counters-mission.jpg');
  // }

  // &-block-stock:before {
  //   background-image: url('/counters/bg-counters-stock.jpg');
  // }

  &-block-stock > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>

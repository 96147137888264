<template>
  <div>
    <div class="hp">
      <BannersFullwidthBanner v-if="homeBanner" :banner="homeBanner" :lazy="false" class="u-m-t-sm home-banner" />
      <BannersReinsuranceBanner />
      <CarouselProductsCarousel
        :lazy="false"
        :references="featuredProducts"
        :count="featuredProducts.length"
        :horizontal-cell="true"
        translate-key="featuredProducts"
        class="home-padding-top"
        :class="[
          !isMobile && isLoading ? 'height-cls-desktop-featureds' : '',
          isMobile && isLoading ? 'height-cls-mobile-featureds' : '',
        ]"
        element-test="featured-products"
        :fast-add-to-cart="true"
      />
      <!-- Prevent merchants carousel to jump for CLS, padding-top on carousels is not working -->
      <div v-if="isLoading" class="home-padding-top" />
      <CarouselFeaturedSellersCarousel
        v-if="featuredSellers?.length"
        :lazy="false"
        :stories="featuredSellers"
        element-test="featured-sellers"
        class="home-padding-top"
        :class="[
          !isMobile && isLoading ? 'height-cls-desktop-merchants' : '',
          isMobile && isLoading ? 'height-cls-mobile-merchants' : '',
        ]"
      />
      <CarouselProductsCarousel
        v-lazycomponent="true"
        :references="bestSellersReferences"
        :count="bestSellersCount"
        :featured-cell="true"
        link="best-sellers"
        translate-key="bestSellers"
        class="home-padding-top"
        element-test="featured-best-sellers"
        :fast-add-to-cart="true"
      />
      <!-- <FeaturedInspirationsCarousel
        v-if="featuredInspirations?.length"
        :stories="featuredInspirations"
        class="home-padding-top"
      /> -->
      <CarouselFeaturedSeasonalSelectionsCarousel
        v-if="featuredSeasonalSelections?.length"
        v-lazycomponent="true"
        :stories="featuredSeasonalSelections"
        :main-story="featuredSeasonalSelectionsMenu"
        element-test="featured-seasonal"
        class="home-padding-top"
      />
      <CarouselProductsCarousel
        v-lazycomponent="true"
        :references="newProductsReferences"
        :count="newProductsCount"
        :horizontal-cell="true"
        link="new-products"
        translate-key="newProducts"
        class="home-padding-top"
        element-test="featured-new-products"
        :fast-add-to-cart="true"
      />
      <Counters v-lazycomponent="true" class="home-padding-top" element-test="counters" />
      <CarouselFeaturedBrandsCarousel
        v-if="featuredBrands?.length"
        v-lazycomponent="true"
        :title="$t('home.topBrands')"
        :brands="featuredBrands"
        element-test="featured-brands"
        class="home-padding-top"
      />
      <CarouselTrustpilotCarousel
        v-show="tld === 'FR'"
        v-lazycomponent="true"
        class="home-padding-top"
        element-test="featured-trustpilot"
      />
      <SbContent
        v-if="seoContent1"
        v-lazycomponent="true"
        :content="seoContent1"
        class="home-padding-top"
        element-test="seo-content-1"
      />
      <CarouselNewsCarousel v-if="news?.length" v-lazycomponent="true" :news="news" element-test="featured-news" />
      <SbContent
        v-if="seoContent2"
        v-lazycomponent="true"
        :content="seoContent2"
        class="home-padding-top"
        element-test="seo-content-2"
      />
      <SbContent
        v-if="proSolutions"
        v-lazycomponent="true"
        :content="proSolutions"
        :imgcls="true"
        class="hp-pro-solutions"
      />
    </div>
    <MkpAlertsConfirmModal v-if="$route.query.showMkpAlertDeleteSuccess" />
  </div>
</template>

<script setup>
import { useDynamicContent } from '@/composables/useDynamicContent'
import { useDynamicContentStore } from '@/stores/dynamicContent'
import { useClubStore } from '@/stores/club'

definePageMeta({
  layout: computed(() => {
    const clubStore = useClubStore()
    return clubStore.layout
  }),
  auth: false,
})

const { homeHead } = useHeadMeta()
const { head, meta, schema } = homeHead()
useHead(head)
useSeoMeta(meta)
useSchemaOrg(schema)

const { getDynamicContent } = useDynamicContent()
const dynamicContentStore = useDynamicContentStore()
const { $currentUser } = useNuxtApp()
const { tld } = useCurrentLocale()
const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const news = computed(() => dynamicContentStore.getNews)
const homeBanner = computed(() => dynamicContentStore.getHomeBanner($currentUser()))
const featuredSellers = computed(() => dynamicContentStore.getFeaturedSellers)
const featuredBrands = computed(() => dynamicContentStore.getFeaturedBrands)
const seoContent1 = computed(() => dynamicContentStore.getHomeContent('seo-content-1'))
const seoContent2 = computed(() => dynamicContentStore.getHomeContent('seo-content-2'))
const proSolutions = computed(() => dynamicContentStore.getHomeContent('pro-solutions'))
const featuredProducts = computed(() =>
  dynamicContentStore.getFeaturedProducts?.map(ref => (ref ? useReference(ref) : null)).filter(x => x)
)
const featuredSeasonalSelections = computed(() => dynamicContentStore.getFeaturedSeasonalSelections)
const featuredSeasonalSelectionsMenu = computed(() => dynamicContentStore.getFeaturedSeasonalSelectionsMenu)
const bestSellersReferences = computed(() =>
  bestSellers.value?.map(ref => (ref ? useReference(ref) : null)).filter(x => x)
)
const newProductsReferences = computed(() =>
  newProducts.value?.map(ref => (ref ? useReference(ref) : null)).filter(x => x)
)
// const mainSlides = computed(() => dynamicContentStore.getMainSlides)
// const featuredInspirations = computed(() => dynamicContentStore.getFeaturedInspirations)
const bestSellers = ref([])
const bestSellersCount = ref(0)
const newProducts = ref([])
const newProductsCount = ref(0)

const { isMobile } = useDeviceSize()

const { request, isLoading } = useApiRequest()

try {
  const reqs = [
    request({
      req: nuxtApp.$api().reference.featured(),
    }),
    getDynamicContent({
      contents: [
        'homeBanners',
        'news',
        'homeContent',
        'featuredSeasonalSelections',
        // 'featuredinspirations',
        'featuredSellers',
        config.public.STORYBLOK_ENV === 'production' ? 'FeaturedBrand' : null,
      ],
    }),
  ]
  if (config.public.STORYBLOK_ENV !== 'production') {
    // Split in multiples queries hack because too much drafts brands (60+) blocking banners in dev/qa since stories results are limited to 100
    reqs.push(
      getDynamicContent({
        contents: ['FeaturedBrand'],
      })
    )
    reqs.push(
      getDynamicContent({
        contents: ['news'],
      })
    )
  }
  const [featuredProductsResults] = await Promise.allSettled(reqs)
  dynamicContentStore.setFeaturedProducts(featuredProductsResults.value?.references || [])
} catch (e) {
  console.error(e)
}

onMounted(() => {
  isLoading.value = false
  const dynamicContentStore = useDynamicContentStore()
  dynamicContentStore.fetchCounters()
  fetchCarousels()
  const { $bus } = useNuxtApp()
  const { analytics, pageTemplate } = useAnalytics()
  analytics.sendScreen(pageTemplate.HOMEPAGE)
  const route = useRoute()
  if (route.query.showMkpAlertDeleteSuccess) {
    setTimeout(() => {
      $bus.emit('modalOpen_deleteMkpAlertModal', 'deleteMkpAlertModal')
    }, 500)
  }
})
const fetchCarousels = async () => {
  const { $api } = useNuxtApp()
  const params = { perPage: 6, page: 1 }
  const [bestSellersResults, newProductsResults] = await Promise.allSettled([
    request({
      req: $api().reference.bestSellers(params),
    }),
    request({
      req: $api().reference.fresh(params),
    }),
  ])
  bestSellers.value = bestSellersResults?.value?.references || []
  bestSellersCount.value = bestSellersResults?.value?.total || 0
  newProducts.value = newProductsResults?.value?.references || []
  newProductsCount.value = newProductsResults?.value?.total || 0
}
</script>

<style lang="scss">
@import '@/styles/partials/components/custom-carousel';

.hp {
  position: relative;
  width: 100%;

  @include mq($mq-sm) {
    width: 100%;
    left: 0;
    padding: 0 $spacing-md;
  }

  .section {
    padding: 100px 0;
  }
  .home-padding-top {
    padding-top: $spacing-xxl;
    @include mq($mq-sm) {
      padding-top: $spacing-md;
    }
  }
  &-pro-solutions {
    h1 {
      font-size: pxToRem(48px);
      @include mq($mq-md) {
        font-size: pxToRem(30px);
      }
    }
    .sb-fullwidth-banner {
      padding: 0;
    }
    .inner-container.fad {
      max-width: 100%;
      padding: 0;
    }
    .sb-cta-groups {
      margin: 0;
    }
    @include mq($mq-sm) {
      .sb-fullwidth-banner-flex {
        flex-direction: column;
      }
    }
  }
  .carousel-wrapper {
    min-height: 90px;
  }
}

.height-cls-desktop {
  &-featureds {
    height: 271px;
  }
  &-merchants {
    height: 341px;
  }
}

.height-cls-mobile {
  &-featureds {
    height: 306px;
  }
  &-merchants {
    height: 376px;
  }
}
</style>

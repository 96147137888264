<template>
  <LinkResolver
    :key="story.name"
    class="featured-carousel-item"
    :style="story.main_color ? `--background-item: ${story.main_color}` : ''"
    :link="story.url"
    :data-test-id="testId + '-carousel-item-' + index"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <div class="featured-carousel-item-top" :data-test-id="testId + '-carousel-item-top-' + index">
      <h3
        v-if="mainItem"
        class="featured-carousel-item-top-subtitle"
        :data-test-id="testId + '-carousel-item-subtitle-' + index"
      >
        {{ story.subtitle }}
      </h3>
      <ImageLazy
        v-if="story.image_url || story.image?.filename"
        class-variant="featured-carousel-item-top-image"
        :src="story.image_url || story.image?.filename"
        :alt="story.name"
        :background-image="true"
        :width="String(itemWidth)"
        :height="String(itemHeight)"
        :data-test-id="testId + '-carousel-item-image-' + index"
      />
      <main-button
        :label="mainItem ? story.main_button_text : story.name"
        tag="span"
        :link="story.url"
        class="featured-carousel-item-top-button"
        :class-variant="mainItem ? 'btn-blue' : 'btn-white-blue'"
        :data-test-id="testId + '-carousel-item-button-' + index"
      />
      <ImageLazy
        v-if="mainItem"
        class-variant="featured-carousel-item-top-icon"
        :src="
          hovered
            ? story.main_icon_off_url || story.main_icon_off?.filename
            : story.main_icon_on_url || story.main_icon_on?.filename
        "
        :alt="story.name"
        :background-image="true"
        :data-test-id="testId + '-carousel-item-icon-' + index"
        width="24"
        height="24"
      />
    </div>
    <div
      v-if="story.description && showDescription"
      class="featured-carousel-item-description"
      :data-test-id="testId + '-carousel-item-description-' + index"
    >
      {{ story.description }}
    </div>
  </LinkResolver>
</template>

<script>
import LinkResolver from '@/components/LinkResolver/LinkResolver'
import MainButton from '@/components/Buttons/MainButton/MainButton'
export default {
  name: 'FeaturedCarouselItem',
  components: {
    MainButton,
    LinkResolver,
  },
  props: {
    story: { type: Object, default: null },
    mainItem: { type: Boolean, default: false },
    showDescription: { type: Boolean, default: true },
    itemWidth: { type: [String, Number], default: null },
    itemHeight: { type: [String, Number], default: null },
    testId: { type: String, default: '' },
    index: { type: Number, default: 0 },
  },
  data() {
    return {
      hovered: false,
    }
  },
}
</script>

<style lang="scss">
.featured-carousel-item {
  cursor: pointer;
  min-width: var(--item-width);
  text-decoration: none;
  width: var(--item-width);
  &:hover {
    text-decoration: underline;
    color: var(--dark-blue);
    .btn {
      background-color: var(--dark-blue);
      color: var(--white);
    }
    .btn.btn-blue {
      color: var(--dark-blue);
      background-color: var(--white);
    }
    .featured-carousel-item-top {
      background-color: var(--dark-blue);
      &-subtitle {
        color: var(--white);
        text-decoration: none;
      }

      &-icon {
        filter: invert(100%);
      }
    }
  }
  &-top {
    background-color: var(--background-item);
    border-radius: $spacing-xs;
    height: var(--item-height);
    margin-bottom: $spacing-sm;
    position: relative;
    &-image {
      border-radius: $spacing-xs;
      img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    &-subtitle {
      padding: $spacing-md;
    }
    &-button {
      bottom: $spacing-md;
      left: $spacing-md;
      max-width: calc(var(--item-width) - #{$spacing-md} * 2);
      position: absolute !important;
      .btn-label {
        font-size: pxToRem(15px) !important;
        font-weight: 800;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(var(--item-width) - #{$spacing-md} * 4);
      }
    }
    &-icon {
      bottom: $spacing-lg;
      position: absolute !important;
      right: $spacing-md;
    }
  }
  &-description {
    font-size: pxToRem(15px);
    color: var(--dark-blue);
  }
}
</style>

<template>
  <div
    v-show="filteredStories?.length > 3"
    id="featuredSeasonalSelectionsWrapper"
    class="featured-seasonal-selections-carousel"
    :data-test-id="elementTest"
  >
    <CarouselContainer
      element-parent="featuredSeasonalSelectionsWrapper"
      :data-test-id="elementTest + '-carousel'"
      :test-id="elementTest"
      item-width="225"
      item-height="325"
    >
      <template #contentCarouselContainer>
        <FeaturedCarouselItem
          v-if="mainStory"
          :key="mainStory.uid"
          :story="mainStory"
          :test-id="elementTest + '-selections'"
          item-width="225"
          item-height="325"
          :main-item="true"
        />
        <FeaturedCarouselItem
          v-for="(story, i) in stories"
          v-show="activeStory(story)"
          :key="story.uid"
          :story="story"
          :index="i"
          :test-id="elementTest"
          item-width="225"
          item-height="325"
        />
      </template>
    </CarouselContainer>
  </div>
</template>

<script>
import { useCategory } from '@/composables/useCategory'
import CarouselContainer from '@/components/Carousel/CarouselContainer'
import FeaturedCarouselItem from '@/components/Carousel/FeaturedCarouselItem/FeaturedCarouselItem'
import i18nMx from '@/mixins/i18n-mixin'

export default {
  name: 'FeaturedSeasonalSelectionsCarousel',
  components: {
    CarouselContainer,
    FeaturedCarouselItem,
  },
  mixins: [i18nMx],
  props: {
    stories: { type: Array, default: () => [] },
    mainStory: { type: Object, default: () => {} },
    elementTest: { type: String, default: '' },
  },
  computed: {
    allCategories() {
      const categories = this.$store.state.categories.available
      const newCategories = []

      for (let i = 0; i < categories.length; i++) {
        newCategories.push(useCategory(categories[i]))
      }

      return newCategories
    },
    filteredStories() {
      const activeStories = []
      for (const story of this.stories) {
        if (this.activeStory(story)) {
          activeStories.push(story)
        }
      }
      return activeStories
    },
  },
  methods: {
    // Not working well, deactivate for now
    activeStory() {
      // (story) in param
      // let category = null
      // if (story?.url?.includes('/category')) {
      //   let storyCategory = story?.url?.split('/')?.pop()?.split('?')?.shift()
      //   category = this.$store.getters['categories/availableCategoryBySlug'](storyCategory)
      //   return !!category && !!((category.mkp_products_count || category.productsCount) >= 1)
      // }
      return true
    },
  },
}
</script>
<style lang="scss">
.featured-seasonal-selections-carousel {
  .featured-carousel-item-top-button {
    padding: 11px 20px;
    text-align: left;
    .btn-label {
      white-space: normal !important;
      line-height: pxToRem(20px);
    }
  }
}
</style>
